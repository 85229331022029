import React from 'react'

const SystemDefinitionOutput = ({ survey, product, stack }) => {
  let summary
  try {
    summary = JSON.parse(survey?.clinicalSafetySummary)
  } catch (e) {}

  // const practiceAndProductSummaryOutput = summary?.practiceAndProductSummaryOutput

  return (
    <>
      <div>
        <h2 className="font-semibold">System Definition / Overview </h2>
        <p>[Placeholder]</p>
        {/* <div dangerouslySetInnerHTML={{ __html: practiceAndProductSummaryOutput }}></div> */}
      </div>
      <br />
    </>
  )
}

export default SystemDefinitionOutput

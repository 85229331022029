import React from 'react'

const ClinicalRiskManagementSystemOutput = ({ survey }) => {
  return (
    <>
      <div>
        <h2 className="font-semibold">Clinical Risk Management System</h2>
        <h3>Introduction</h3>
        <p>
          This Clinical Risk Management System (CRMS) outlines the processes to be followed to
          ensure that all healthcare IT used to support care within the Organisation is developed,
          implemented and used in as safe manner.
        </p>
        <p>
          This CRMS provides a framework that promotes the effective risk management, by the
          Organisation, of potential health IT hazards and operational incidents.
        </p>
        <p>
          This CRMS compliments existing risk management processes that should be defined in the
          Organisation's Risk Management Strategy and wherever practical, uses existing procedures,
          processes and governance arrangements.
        </p>
        <p>
          This CRMS addresses the requirements of DCB0129 and DCB0160 and follows best practice as
          promoted by NHS Digital.
        </p>
        <p>
          This CRMS will be reviewed and maintained in accordance with the Organisation's policy
        </p>
        <h3>Purpose</h3>
        <p>
          The aim of the CRMS is to ensure that all of the Organisational staff involved with the
          development, implementation and use of healthcare IT systems are aware of the activities
          that are required to be undertaken to ensure patient safety is improved rather than
          compromised from the introduction of healthcare IT systems.
        </p>
        <p>
          The Organisation is required to adhere to National Information standards created and
          monitored via the Data Coordination Board (DCB) within NHS Information Standards
          frameworks.
        </p>
        <p>
          The mechanisms used are approved process Clinical Risk Management System compliance
          documents.
        </p>
        <p>This Clinical Risk Management System will be reviewed periodically to ensure that: </p>
        <ul>
          <li>changes in working practices are incorporated</li>
          <li>issues identified though an established internal audit programme are addressed </li>
          <li>
            the safety approach continues to adhere to the requirements of applicable international
            standards
          </li>
          <li>
            the system continues to protect the safety of patients in a complex and changing
            environment
          </li>
        </ul>
        <h3>Audience</h3>
        <p>
          This document is for the Organisational staff that are involved in ensuring the safety of
          healthcare IT systems, products or services.
        </p>
        <h3>Scope</h3>
        <p>
          This applies to the Organisation and to all subsequent updates or upgrades to systems. The
          policy also applies to any local customisations or specific configurations made to a
          healthcare IT system by the Organisation.
        </p>
        <p>
          If clarification is required of whether any system falls within scope of this CRMS this
          should be raised with the nominated Clinical Safety Officer (CSO) for clarification. This
          nominated person provides clinical and organisational leadership on healthcare IT Patient
          Safety on behalf of the Organisation.
        </p>

        <h3>Definitions</h3>
        <p>Note - Also see the Organisation's Risk Management Strategy.</p>
        <p>
          <strong>CSO</strong>: Clinical Safety Officer - the person responsible for ensuring that
          the healthcare IT Clinical Risk Management System is applied to all clinical systems. The
          Clinical Safety Officer (CSO) for the Organisation is responsible for ensuring the safety
          of a healthcare IT system through the application of clinical risk management. The
          Clinical Safety Officer must hold a current registration with an appropriate professional
          body relevant to their training and experience. They also need to be suitably trained and
          qualified in risk management or have an understanding in principles of risk and safety as
          applied to healthcare IT systems. The Clinical Safety Officer ensures that the processes
          defined by the clinical risk management system are followed.{' '}
        </p>
        <p>
          <strong>DCB</strong>: Data Coordination Board{' '}
        </p>

        <h3>Hazard identification and assessment</h3>
        <p>
          Workforce engagement using Bordercross Health clinical safety platform was conducted with
          a survey open to relevant staff, which closed on{' '}
          {new Date(survey?.closedDate).toLocaleDateString()}
        </p>
      </div>
      <br />
    </>
  )
}

export default ClinicalRiskManagementSystemOutput

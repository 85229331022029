import React from 'react'
import HtmlDisplayBox from '../../../HTMLDisplayBox'
import SurveySummaryOutput from './SurveySummaryOutput'
import SurveyResultsOutput from './SurveyResultsOutput'
import SurveyCommentsOutput from './SurveyCommentsOutput'
import SurveyHazardAnalysisOutput from './SurveyHazardAnalysisOutput'
import SurveyHazardLogOutput from './SurveyHazardLogOutput'
import IntroductionOutput from './IntroductionOutput'
import SystemDefinitionOutput from './SystemDefinitionOutput'
import ClinicalRiskManagementSystemOutput from './ClinicalRiskManagementSystemOutput'

const AdminOutputsModal = ({ product, survey, surveyQuestions, stack, setOpen }) => {
  let summary
  try {
    summary = JSON.parse(survey?.clinicalSafetySummary)
  } catch (e) {}

  // Needs adjustment according OpenAi's pricing
  const gpt4oInputCostPerToken = 2.5 / 1000000
  const gpt4oOutputCostCostPerToken = 10 / 1000000

  const promptCost = summary?.hazardLogOutput?.aiTokenCounts?.promptTokens * gpt4oInputCostPerToken
  const completionCost =
    summary?.hazardLogOutput?.aiTokenCounts?.completionTokens * gpt4oOutputCostCostPerToken

  return (
    <>
      <div>
        <h2 className="text-xl sm:text-4xl font-bold">Admin outputs</h2>
        {summary?.updatedAt && (
          <div className="mt-1">
            Updated at: {new Date(summary.updatedAt).toLocaleString('en-GB')}
          </div>
        )}

        {summary?.timeTaken && (
          <div className="mt-1">Time Taken: {Math.round(summary.timeTaken / 100) / 10} seconds</div>
        )}

        {!!promptCost && !!completionCost && (
          <div className="mt-1">
            Prompt cost: £{Math.round(promptCost * 100) / 100}, Completion cost: £
            {Math.round(completionCost * 100) / 100}, Total: £
            {Math.round((promptCost + completionCost) * 100) / 100}
          </div>
        )}

        <div className="mt-4">
          <h3 className="text-xl font-semibold">
            Survey summary, results & clinical safety analysis
          </h3>
          <div className="mt-2">
            <HtmlDisplayBox
              content={
                <>
                  <IntroductionOutput product={product} survey={survey} stack={stack} />

                  <SystemDefinitionOutput product={product} survey={survey} stack={stack} />

                  <ClinicalRiskManagementSystemOutput survey={survey} />

                  <SurveySummaryOutput
                    product={product}
                    survey={survey}
                    surveyQuestions={surveyQuestions}
                  />

                  <SurveyResultsOutput
                    product={product}
                    survey={survey}
                    surveyQuestions={surveyQuestions}
                  />

                  <SurveyCommentsOutput
                    product={product}
                    survey={survey}
                    surveyQuestions={surveyQuestions}
                  />

                  <SurveyHazardAnalysisOutput survey={survey} />
                </>
              }
            />
          </div>
        </div>

        <div className="mt-12">
          <h3 className="text-xl font-semibold">Hazard log</h3>
          <div className="mt-2">
            <HtmlDisplayBox content={<SurveyHazardLogOutput survey={survey} />} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminOutputsModal
